import api from "./index";

export async function startKyc(params) {
  return api.get("/KYC/start", { params });
}

export async function getKycStatus() {
  return api.get("/KYC/get-status");
}

export async function resendEmail() {
  return api.get("/KYC/resend-email");
}

export async function resendSms() {
  return api.get("/KYC/resend-sms");
}
