import store from "../store";

export default async (to, from, next) => {
  document.title =
    (to.meta.title || to.name) + " | " + process.env.VUE_APP_PROJECT_NAME;

  if (to.name != from.name) window.scrollTo(0, 0);

  const requiresAuth = to.meta.requiresAuth || false;
  const preventAuth = to.meta.preventAuth || false;
  const authenticated = store.getters.authenticated;

  if (requiresAuth && !authenticated) return next("/auth");

  if (preventAuth && authenticated) return next("/");

  if (!store.getters.strigaAccess) {
    if (authenticated && requiresAuth && to.name !== "Profile")
      return next("/profile");
  }

  next();
};
