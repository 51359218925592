<template>
  <v-snackbar
    v-model="show"
    class="mt-4"
    color="white"
    rounded="xl"
    :timeout="timeout"
    fixed
    light
    top
  >
    <div class="d-flex align-center">
      <v-icon :color="type" left> {{ icons[type] }} </v-icon>

      <div class="d-flex flex-column">
        <div class="text-subtitle-2">{{ title }}</div>
        <div v-if="subtitle" class="text-caption mt-n1">{{ subtitle }}</div>
      </div>

      <v-spacer />

      <v-btn class="ml-1" icon x-small @click="show = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
  </v-snackbar>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      icons: {
        success: "mdi-check-circle",
        error: "mdi-close-box",
        warning: "mdi-alert-rhombus",
        info: "mdi-information",
      },
      title: "",
      subtitle: "",
      type: "",
      timeout: 4000,
    };
  },

  methods: {
    handleAlert(data) {
      this.title = data.title;
      this.subtitle = data.subtitle || "";
      this.type = data.type;
      this.timeout = data.fixed ? -1 : data.timeout || 4000;
      this.show = true;
    },
  },
};
</script>

<style src="./style.scss" lang="scss" scoped />
