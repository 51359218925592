import api from "./index";

export async function signIn(body) {
  return api.post("/login", body);
}

export async function signUp(body) {
  return api.post("/user", body);
}

export async function loadSession() {
  return api.get("/load-session");
}

export async function strigaSignUp(body) {
  return api.post("/user/create-striga-user", body);
}
