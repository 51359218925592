<template>
  <v-layout class="auth-wrapper">
    <v-container>
      <v-row class="fill-height" justify="center">
        <v-col class="py-6" cols="12" sm="8" md="6" align-self="center">
          <v-row justify="center">
            <v-col cols="12" sm="11" md="10">
              <v-img
                :src="require('@/assets/brand/logotype.svg')"
                class="mb-6"
                max-width="40%"
                contain
              />

              <router-view />
            </v-col>
          </v-row>
        </v-col>

        <v-col v-if="$vuetify.breakpoint.mdAndUp" ols="12" md="6" xl="4">
          <div class="d-flex justify-center align-center" style="height: 100vh">
            <v-img
              class="rounded-xl"
              :src="require(`@/assets/auth/background.svg`)"
              :aspect-ratio="4 / 4"
            >
              <div class="d-flex align-center fill-height">
                <v-row justify="center">
                  <v-col cols="10">
                    <v-img :src="require(`@/assets/auth/img-auth.svg`)" />
                  </v-col>
                </v-row>
              </div>
            </v-img>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-layout>
</template>

<script>
export default {
  name: "Auth",
};
</script>

<style lang="scss" src="./style.scss" scoped />
