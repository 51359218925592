import Axios from "axios";
import store from "@/store";
import router from "@/router";

const api = Axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 16000,
});

if (store && store.getters.token)
  api.defaults.headers.common["Authorization"] = store.getters.token;

// interceptor
api.interceptors.response.use(
  (res) => Promise.resolve(res),
  (err) => {
    if (
      (err === "Error: Network Error" || err.response?.status === 401) &&
      !!store.getters.token
    ) {
      store.dispatch("setSignOut");
      router.push({ path: "/" }).catch(() => {
        /* ignore */
      });
    }

    let response = err.response || {
      data: {
        message: "Tivemos um erro inesperado. Tente novamente mais tarde!",
      },
    };

    if (err.response?.data) {
      if (
        !response.data.message &&
        Array.isArray(response?.data) &&
        response.data.length
      ) {
        response.data = {
          ...response.data,
          message: `${response.data[0].field}: ${response.data[0].error}`,
        };
      } else if (!response?.data?.message && response.data) {
        response.data = { message: response.data };
      }
    }

    return Promise.reject(response);
  }
);

export default api;
