import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from "vuex-persist";
import api from "@/services";

Vue.use(Vuex);

const vuexLocal = new VuexPersist({
  key: process.env.VUE_APP_PROJECT_KEY,
  storage: window.localStorage,
  modules: ["token", "user", "strigaAccess"],
});

export default new Vuex.Store({
  state: {
    token: "",
    user: null,
    strigaAccess: false,
    kyc: {
      emailVerified: false,
      mobileVerified: false,
      status: "INITIATED",
      currentTier: 1,
    },
  },

  getters: {
    authenticated: ({ token, user }) => !!token && !!user,
    token: ({ token }) => token,
    user: ({ user }) => user,
    strigaAccess: ({ strigaAccess }) => strigaAccess,
    kyc: ({ kyc }) => kyc,
  },

  mutations: {
    SET_TOKEN(state, payload) {
      state.token = payload;
    },

    SET_USER(state, payload) {
      state.user = payload;
    },

    SET_STRIGA_ACCESS(state, payload) {
      state.strigaAccess = payload;
    },

    SET_KYC(state, payload) {
      state.kyc = payload;
    },
  },

  actions: {
    setToken({ commit }, payload) {
      commit("SET_TOKEN", payload);
      api.defaults.headers.common["Authorization"] = payload;
    },

    setUser({ commit, dispatch }, payload) {
      commit("SET_USER", payload);
      dispatch("setStrigaAccess", !!payload?.strigaUserCreated);
    },

    setSignIn({ dispatch }, payload) {
      dispatch("setToken", payload.token);
      dispatch("setUser", payload.user);
    },

    setSignOut({ dispatch }) {
      dispatch("setToken", "");
      dispatch("setUser", null);
    },

    setStrigaAccess({ commit }, payload) {
      commit("SET_STRIGA_ACCESS", payload);
    },

    setKyc({ commit }, payload) {
      commit("SET_KYC", payload);
    },
  },

  modules: {},

  plugins: [vuexLocal.plugin],
});
