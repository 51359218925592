<template>
  <v-layout class="main-wrapper" column>
    <v-app-bar class="header-bar" color="transparent" flat>
      <v-btn class="btn-icon" icon @click="handleDrawer()">
        <v-icon>mdi-menu</v-icon>
      </v-btn>

      <v-spacer />

      <v-btn
        class="btn rounded-lg mr-4"
        color="primary"
        :to="{ name: 'Payment' }"
        :disabled="!_strigaAccess"
        depressed
      >
        Pay now
      </v-btn>

      <v-avatar size="2.5rem" color="neutral4">
        <v-icon color="white" small>fa-regular fa-user</v-icon>
      </v-avatar>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      class="side-drawer"
      color="neutral1"
      app
    >
      <div class="d-flex flex-column fill-height">
        <div class="d-flex justify-center py-6">
          <v-img
            :src="require('@/assets/brand/logotype.svg')"
            max-width="50%"
            contain
          />
        </div>

        <v-divider />

        <router-link class="text-decoration-none" :to="{ name: 'Profile' }">
          <v-list-item class="py-2">
            <v-list-item-avatar>
              <v-avatar size="2.5rem" color="neutral4">
                <v-icon color="white" small>fa-regular fa-user</v-icon>
              </v-avatar>
            </v-list-item-avatar>

            <v-list-item-title>
              {{ _user.firstName }} {{ _user.lastName }}
            </v-list-item-title>
          </v-list-item>
        </router-link>

        <v-divider />

        <v-list class="pa-4" color="transparent" nav>
          <router-link
            v-for="(item, i) in _routes"
            v-slot="{ href, navigate, isActive }"
            :to="item.path"
            :key="i"
            custom
          >
            <v-list-item
              class="router-item rounded-lg"
              :class="{ neutral3: isActive }"
              :disabled="!_strigaAccess"
              :href="href"
              @click="navigate"
            >
              <v-list-item-icon class="mr-2">
                <v-icon :color="isActive ? 'white' : 'neutral5'" small>
                  {{ item.icon }}
                </v-icon>
              </v-list-item-icon>

              <v-list-item-title
                :class="{
                  '': isActive,
                  'neutral5--text': !isActive,
                }"
              >
                {{ item.label }}
              </v-list-item-title>
            </v-list-item>
          </router-link>
        </v-list>
      </div>

      <template v-slot:append>
        <v-list class="transparent" nav>
          <v-list-item class="router-item rounded-lg" @click="signOut()">
            <v-list-item-icon class="mr-2">
              <v-icon> mdi-logout </v-icon>
            </v-list-item-icon>

            <v-list-item-title> Sign Out </v-list-item-title>
          </v-list-item>
        </v-list>
      </template>
    </v-navigation-drawer>

    <router-view />
  </v-layout>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Main",

  data() {
    return {
      drawer: true,
    };
  },

  computed: {
    ...mapGetters({
      _user: "user",
      _strigaAccess: "strigaAccess",
    }),

    _routes() {
      return [
        {
          label: "Home",
          path: "/home",
          icon: "fa-solid fa-house",
        },
        {
          label: "Wallets",
          path: "/wallets",
          icon: "fa-solid fa-wallet",
        },
        {
          label: "Cards",
          path: "/cards",
          icon: "fa-regular fa-credit-card",
        },
        {
          label: "Payment",
          path: "/payment",
          icon: "fa-solid fa-arrows-left-right",
        },
        {
          label: "Transactions",
          path: "/transactions",
          icon: "fa-solid fa-list",
        },
      ];
    },
  },

  methods: {
    handleDrawer() {
      this.drawer = !this.drawer;
    },

    signOut() {
      this.$root.$emit("sign-out");
    },
  },
};
</script>

<style lang="scss" src="./style.scss" scoped />
