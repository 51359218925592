<template>
  <v-app id="app">
    <v-main>
      <SplashScreen v-if="loading" />

      <router-view v-else />
    </v-main>

    <AlertBar ref="alertBar" />
  </v-app>
</template>

<script>
import { mapActions } from "vuex";
import { loadSession } from "@/services/auth";
import { getKycStatus } from "@/services/kyc";
import SplashScreen from "@/components/splashScreen/SplashScreen";
import AlertBar from "@/components/alertBar/AlertBar";

export default {
  name: "App",

  components: {
    SplashScreen,
    AlertBar,
  },

  data() {
    return {
      loading: true,
    };
  },

  created() {
    this.handleSession();
  },

  beforeMount() {
    this.$root.$on("alert", this.handleAlert);
    this.$root.$on("sign-out", this.signOut);
  },

  beforeDestroy() {
    this.$root.$off("alert", this.handleAlert);
    this.$root.$off("sign-out", this.signOut);
  },

  methods: {
    ...mapActions(["setUser", "setSignOut", "setKyc"]),

    async handleSession() {
      const token = this.$store.getters.token;

      if (token) {
        try {
          const { data } = await loadSession();

          this.setUser(data);

          await this.getKycStatus();
        } catch {
          this.signOut();
        }
      }

      setTimeout(() => {
        this.loading = false;
      }, 1000);
    },

    async getKycStatus() {
      try {
        const { data } = await getKycStatus();

        await this.setKyc(data.KYC);
      } catch (error) {
        this.handleAlert(error.data.message, "error");
      }
    },

    async signOut() {
      try {
        this.loading = true;
        await this.setSignOut();

        if (this.$route.name !== "SignIn")
          this.$router.push({ name: "SignIn" });
      } catch (error) {
        this.handleAlert(error.data.message, "error");
      } finally {
        setTimeout(() => {
          this.loading = false;
        }, 1000);
      }
    },

    handleAlert(event) {
      this.$refs.alertBar.handleAlert(event);
    },
  },
};
</script>

<style lang="scss">
html {
  background: $neutral-1;
}

#app {
  background: $neutral-1;
}
</style>
