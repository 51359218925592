import Vue from "vue";
import VueRouter from "vue-router";
import beforeEach from "./beforeEach";
import Main from "../modules/main/Main.vue";
import Auth from "../modules/auth/Auth.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: { name: "Home" },
  },
  {
    path: "/",
    name: "Main",
    redirect: { name: "Home" },
    component: Main,
    children: [
      {
        path: "home",
        name: "Home",
        meta: { title: "Home", requiresAuth: true },
        component: () => import("@/modules/main/views/home/Home.vue"),
      },
      {
        path: "payment/:walletID?/:accountID?",
        name: "Payment",
        meta: { title: "Payment", requiresAuth: true },
        component: () => import("@/modules/main/views/payment/Payment.vue"),
      },
      {
        path: "cards",
        name: "Cards",
        redirect: { name: "MyCards" },
        component: () => import("@/modules/main/views/cards/Cards.vue"),
        children: [
          {
            path: "all",
            name: "MyCards",
            meta: { title: "Cards", requiresAuth: true },
            component: () =>
              import("@/modules/main/views/cards/children/myCards/MyCards.vue"),
          },
          {
            path: "details/:id",
            name: "CardDetails",
            meta: { title: "Card Information", requiresAuth: true },
            component: () =>
              import(
                "@/modules/main/views/cards/children/cardDetails/CardDetails.vue"
              ),
          },
          {
            path: "create-card",
            name: "CreateCard",
            meta: { title: "Create Card", requiresAuth: true },
            component: () =>
              import(
                "@/modules/main/views/cards/children/createCard/CreateCard.vue"
              ),
          },
        ],
      },
      {
        path: "wallets",
        name: "Wallets",
        redirect: { name: "MyWallets" },
        component: () => import("@/modules/main/views/wallets/Wallets.vue"),
        children: [
          {
            path: "all",
            name: "MyWallets",
            meta: { title: "Wallets", requiresAuth: true },
            component: () =>
              import(
                "@/modules/main/views/wallets/children/myWallets/MyWallets.vue"
              ),
          },
          {
            path: "details/:id",
            name: "WalletDetails",
            meta: { title: "Wallet Information", requiresAuth: true },
            component: () =>
              import(
                "@/modules/main/views/wallets/children/walletDetails/WalletDetails.vue"
              ),
          },
          {
            path: "account/:walletID/:accountID",
            name: "Account",
            meta: { title: "Account Details", requiresAuth: true },
            component: () =>
              import(
                "@/modules/main/views/wallets/children/account/Account.vue"
              ),
          },
        ],
      },
      {
        path: "transactions",
        name: "Transactions",
        meta: { title: "Transactions", requiresAuth: true },
        component: () =>
          import("@/modules/main/views/transactions/Transactions.vue"),
      },
      {
        path: "profile",
        name: "Profile",
        meta: { title: "Profile", requiresAuth: true },
        component: () => import("@/modules/main/views/profile/Profile.vue"),
      },
      {
        path: "kyc",
        name: "Kyc",
        meta: { title: "KYC", requiresAuth: true },
        component: () => import("@/modules/main/views/kyc/Kyc.vue"),
      },
    ],
  },
  {
    path: "/auth",
    name: "Auth",
    redirect: { name: "SignIn" },
    component: Auth,
    children: [
      {
        path: "sign-in",
        name: "SignIn",
        meta: { title: "Sign In", preventAuth: true },
        component: () => import("@/modules/auth/views/signIn/SignIn.vue"),
      },
      {
        path: "sign-up",
        name: "SignUp",
        meta: { title: "Sign Up", preventAuth: true },
        component: () => import("@/modules/auth/views/signUp/SignUp.vue"),
      },
    ],
  },
  {
    path: "*",
    redirect: { name: "Home" },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(beforeEach);

export default router;
