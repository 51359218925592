import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import en from "vuetify/lib/locale/en";
import "@fortawesome/fontawesome-free/css/all.css";
import _colors from "../styles/variables/_colors.module.scss";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: true,
    options: {
      customProperties: true,
    },
    themes: {
      dark: {
        primary: _colors.primary,
        secondary: _colors.secondary,
        accent: _colors.accent,
        error: _colors.error,
        info: _colors.info,
        success: _colors.success,
        warning: _colors.warning,
        neutral: _colors.neutral,
        neutral1: _colors.neutral1,
        neutral2: _colors.neutral2,
        neutral3: _colors.neutral3,
        neutral4: _colors.neutral4,
        neutral5: _colors.neutral5,
        neutral6: _colors.neutral6,
        neutral7: _colors.neutral7,
        neutral8: _colors.neutral8,
        neutral9: _colors.neutral9,
        neutral10: _colors.neutral10,
        neutral11: _colors.neutral11,
        neutral12: _colors.neutral12,
        neutral13: _colors.neutral13,
      },
    },
  },
  icons: {
    iconfont: "fa",
  },
  lang: {
    locales: { en },
    current: "en",
  },
});
