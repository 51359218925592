<template>
  <div class="d-flex align-center justify-center">
    <v-progress-circular indeterminate size="48" color="white" />
  </div>
</template>

<script>
export default {
  name: "Loader",
};
</script>

<style src="./style.scss" lang="scss" scoped />
